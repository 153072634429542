.loaderBox{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 11;
    background-color: #05176c;
    top: 0;
    left: 0;
    span{
        width: 48px;
        height: 48px;
        border: 3px solid #ffffff;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        animation: rotation 1s linear infinite;
        &::after{
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-bottom-color: #004bc8;
        }
    }
    @keyframes rotation {
        0% { transform: rotate(0deg) }
        100% { transform: rotate(360deg) }
    }
}